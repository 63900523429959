define("discourse/plugins/discourse-cakeday/discourse/templates/components/user-info-list", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="user-info-list">
    {{#each users as |user|}}
      <li class="user-info-item">
        <UserInfo @user={{user}}>
          <div>{{cakeday-date user.cakedate isBirthday=isBirthday}}</div>
        </UserInfo>
      </li>
    {{else}}
      <div class="user-info-empty-message"><h4>{{yield}}</h4></div>
    {{/each}}
  </ul>
  */
  {
    "id": "Pu+Gg6YH",
    "block": "[[[10,\"ul\"],[14,0,\"user-info-list\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"user-info-item\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,[28,[35,6],[[30,1,[\"cakedate\"]]],[[\"isBirthday\"],[[30,0,[\"isBirthday\"]]]]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,0],[14,0,\"user-info-empty-message\"],[12],[10,\"h4\"],[12],[18,2,null],[13],[13],[1,\"\\n\"]],[]]],[13],[1,[28,[32,0],[\"[[\\\"The `users` property path was used in the `discourse/plugins/discourse-cakeday/discourse/templates/components/user-info-list.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.users}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `isBirthday` property path was used in the `discourse/plugins/discourse-cakeday/discourse/templates/components/user-info-list.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.isBirthday}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"user\",\"&default\"],false,[\"ul\",\"each\",\"-track-array\",\"li\",\"user-info\",\"div\",\"cakeday-date\",\"h4\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-cakeday/discourse/templates/components/user-info-list.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});