define("discourse/plugins/discourse-cakeday/discourse/templates/components/user-age-title", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/is-component", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _isComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
      <div title={{titleText}}>
        {{userage}}
    </div>
  
  */
  {
    "id": "79l1yuJf",
    "block": "[[[1,\"  \"],[44,[[28,[37,1],null,[[\"titleText\"],[[28,[32,0],[\"titleText\"],null]]]]],[[[10,0],[15,\"title\",[52,[30,1,[\"titleText\"]],[28,[30,1,[\"titleText\"]],null,null],[28,[32,1],[[30,0],\"titleText\",\"[\\\"The `titleText` property path was used in the `discourse/plugins/discourse-cakeday/discourse/templates/components/user-age-title.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.titleText}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[12],[1,\"\\n      \"],[41,[28,[32,2],[\"userage\"],null],[[[8,[39,4],null,null,null]],[]],[[[44,[[28,[37,1],null,[[\"userage\"],[[28,[32,0],[\"userage\"],null]]]]],[[[1,[52,[30,2,[\"userage\"]],[28,[30,2,[\"userage\"]],null,null],[28,[32,1],[[30,0],\"userage\",\"[\\\"The `userage` property path was used in the `discourse/plugins/discourse-cakeday/discourse/templates/components/user-age-title.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.userage}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[2]]]],[]]],[1,\"\\n  \"],[13]],[1]]],[1,\"\\n\"]],[\"maybeHelpers\",\"maybeHelpers0\"],false,[\"let\",\"hash\",\"div\",\"if\",\"userage\"]]",
    "moduleName": "discourse/plugins/discourse-cakeday/discourse/templates/components/user-age-title.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _isComponent.default],
    "isStrictMode": false
  });
});